=at-min-width($width)
    @media only screen and (min-width: $width)
        @content

=at-max-width($width)
    @media only screen and (max-width: $width)
        @content

=at-min-max-width($min-width, $max-width)
    @media only screen and (min-width: $min-width) and (max-width: $max-width)
        @content

=at-max-height($height)
    @media only screen and (max-height: $height)
        @content

=css-arrow($direction, $size, $border-width, $color)
    width: $size
    height: $size
    border: $border-width solid $color
    transform: rotateZ(45deg)

    @if $direction == top
        border-right-style: none
        border-bottom-style: none

    @else if $direction == right
        border-left-style: none
        border-bottom-style: none

    @else if $direction == bottom
        border-left-style: none
        border-top-style: none

    @else
        border-top-style: none
        border-right-style: none

=css-plus-sign($size, $border-width, $color)
    width: $size
    height: $size
    cursor: pointer
    position: relative

    &:before,
    &:after
        content: ' '
        height: $border-width
        width: $size
        background: $color
        position: absolute
        top: calc(50%  - calc($border-width / 2))
        left: calc(50% - calc($size / 2))
        display: block
        transform-origin: 50% 50%

    &:before
        transform: rotate(90deg)

=font-face($font-family, $font-style, $font-weight, $src-url, $format)
    @font-face
        font-family: $font-family
        font-style: $font-style
        font-weight: $font-weight
        src: url($src-url) format($format)