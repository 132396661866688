.nb-modal
    display: none

.show-nb-modal
    .nb-modal-overlay
        content: ' '
        position: fixed
        background-color: rgba(0, 0, 0, 0.2)
        backdrop-filter: saturate(170%) blur(4px)
        top: 0
        right: 0
        bottom: 0
        left: 0

    .nb-modal
        position: fixed
        top: 0
        right: 0
        bottom: 0
        left: 0
        display: flex
        align-items: center
        justify-content: center
        z-index: 11

.nb-modal-container
    position: relative
    width: calc(100% - 100px)
    max-width: calc(1200px + 100px)
    margin: 50px
    background-color: $color-grey-x-light
    z-index: 12
    box-shadow: 30px 30px 90px rgba(0, 0, 0, 0.5)

.nb-modal-content
    overflow-y: auto
    max-height: calc(100vh - 120px)
    padding: 60px

.nb-modal-close
    position: absolute
    top: 10px
    right: 10px
    width: 50px
    height: 50px
    cursor: pointer
    z-index: 12

    &:after,
    &:before
        content: ' '
        cursor: pointer
        border-radius: 1px
        height: 2px
        width: 40px
        background-color: #000
        position: absolute
        display: block
        left: 7px
        transition: all 0.2s ease-in-out
        transform-origin: 50% 50%
        top: 20px

    &:before
        transform: rotate(45deg)

    &:after
        transform: rotate(-45deg)

    &:hover
        &:after,
        &:before
            background-color: $color-brand-orange