.list-super-category
    padding-bottom: 2px
    border-bottom: 1px solid silver

.list-sub-category
    padding-left: 30px
    display: none
    flex-direction: column

.list-category-title
    color: $color-brand-orange
    font-size: 20px
    padding: 20px 0
    cursor: pointer
    display: flex
    justify-content: space-between
    text-decoration: underline
    letter-spacing: 0.05em
    text-transform: uppercase

.list-category-link
    display: block
    margin-bottom: 30px
    text-decoration: none
    color: $color-font-body
    text-transform: uppercase

.icon-toggle
    svg
        display: block

.list-super-category-item-has-visible-content
    .list-sub-category
        display: flex

    .icon-toggle
        transform: rotate(180deg)

.description-text
    font-size: 0.7rem
    color: $color-grey-light
    margin: 0
    padding: 0
