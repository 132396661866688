.order-confirmation

    .cards
        display: flex
        gap: 5px // sass-lint:disable-line no-misspelled-properties

    .card
        @extend %box-default
        width: 100%

        .outer-row
            border-bottom: 1px solid $color-grey-medium
            padding-bottom: 15px

            &:last-child
                border-bottom: 0
                padding-bottom: 0

            h3
                font-size: 1.2rem

            .sub-title
                font-weight: 600

        .inner-row
            display: flex
            justify-content: space-between
            color: $color-grey-medium

            &--bold
                color: #343536

            &--product-row
                gap: 20px // sass-lint:disable-line no-misspelled-properties

                .sub-title
                    color: #343536

                *
                    width: 100%

                    &:last-child
                        text-align: right

            p
                padding: 0
                margin: 0

    .new-order-btn
        width: 20%
        text-align: center
