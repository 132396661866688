.search-products
    flex: 1 0 auto
    margin-right: 40px

    +at-max-width($bp-mobile)
        margin-left: 60px

.search-products-results
    position: fixed
    top: 62px
    right: 0
    height: 100vh
    max-height: calc(100vh - 60px)
    left: $width-navigation
    background-color: #fff
    display: none

    +at-max-width($bp-mobile)
        left: 0

.has-visible-search-products-results
    .search-products-results
        display: block

.search-products-fieldset
    display: flex
    margin-left: -10px

    svg
        width: 40px
        height: 40px

.search-products-input
    width: 100%
    padding-top: 10px
    padding-bottom: 10px

.search-products-clear
    display: flex
    align-items: center
    justify-content: center
    opacity: 0

    svg
        width: 16px
        height: 16px

.search-products-has-query
    .search-products-clear
        transition: opacity 0.2s ease
        opacity: 0.5

        &:hover
            opacity: 1
            cursor: pointer

.search-products-results-close
    position: absolute
    cursor: pointer
    top: 20px
    right: 70px

    svg
        width: 30px
        height: 30px

    line
        transition: stroke 0.2s ease

    &:hover
        line
            stroke: $color-brand-orange

.search-products-results-content
    padding: 40px 70px
    overflow-x: auto
    height: 100vh
    max-height: calc(100vh - 60px)

    .shop-items
        grid-template-columns: 1fr
        margin-top: 60px

    .shop-item
        display: flex
        flex-direction: row
        align-items: center
        padding: 0
        box-shadow: none

    .shop-item-figure
        max-width: 50px

    .shop-item-title
        flex: 1 0 auto
        text-align: left
        margin: 0 0 0 20px

.search-result-item-title
    color: $color-font-body
    transition: color 0.2s ease

.search-result-items
    display: grid
    grid-gap: 20px
    margin-top: 60px

.search-result-item
    display: grid
    grid-gap: 20px
    grid-template-columns: 80px calc(100% - 80px)

    &:hover
        text-decoration: none
        cursor: pointer

        .search-result-item-title
            color: $color-link

.search-result-item-figure
    position: relative
    width: 100%
    min-height: 1px // Fixes flexbox in IE11

    &:before
        content: ' '
        display: block
        width: 100%
        padding-top: 100%

.search-result-item-figure-content
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden

    img
        max-height: 100%
        flex: 0 0 auto // Fixes flexbox in IE11
