$icon-check-mark: '\2713'

.product
    @extend %box-default
    max-width: 720px
    display: grid
    grid-gap: 20px
    grid-template-columns: repeat(2, minmax(0, 50%))

.product-figure
    display: flex
    justify-content: center
    align-items: center
    padding: 30px

.product-details
    display: flex
    flex-direction: column
    justify-content: space-between

.product-description
    h2
        @extend %bold-font
        margin-bottom: 10px

.product-sku
    opacity: 0.5
    font-size: 0.8em

    &:before
        content: 'Artikelnummer: '

.product-price
    @extend %bold-font

    .currency
        padding-left: 3px

.product-quantity
    width: 80px

    .label
        @extend %bold-font
        text-transform: uppercase
        font-size: 10px
        text-align: center
        margin-bottom: 4px

.quantity-select
    display: flex
    justify-content: center
    border-radius: 20px
    border: 1px solid #000

    .quantity
        width: 20px
        height: 34px
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        transition: all 0.2s ease

        svg
            max-width: 12px

        &:hover
            fill: $color-brand-orange

        &:active
            svg
                transform: scale(0.8)

.quantity-field
    width: 30px
    text-align: center
    margin: 0
    -moz-appearance: textfield // sass-lint:disable-line no-vendor-prefixes

    &::-webkit-outer-spin-button, // sass-lint:disable-line no-vendor-prefixes
    &::-webkit-inner-spin-button // sass-lint:disable-line no-vendor-prefixes
        -webkit-appearance: none // sass-lint:disable-line no-vendor-prefixes

.add-to-cart-btn
    margin-top: 60px
    width: 100%
    position: relative

    .btn-text
        display: inline-block
        transition: transform 0.15s ease-in-out


.add-to-cart-added
    &[disabled]
        opacity: 1

    .btn-text
        transform: scale(0)
        opacity: 0
        transition: all 0 ease-in-out

    &:after
        content: $icon-check-mark
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        display: flex
        justify-content: center
        align-items: center
        font-size: 1.4em
        animation: singlebeat 0.4s ease-in-out both
        color: #fff

    &:hover
        background-color: #000
