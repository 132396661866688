.page-content-finance
    .sales-chart
        width: 100%
        margin-left: 0
        order: -1

    .sales-numbers
        width: 100%
        margin-top: $margin-medium

    .sales-items
        display: flex
        flex-wrap: wrap
        margin-left: -#{$margin-medium}

    .sales-item
        width: calc(#{columns-to-percent(2)} - #{$margin-medium})
        margin-left: $margin-medium
        margin-bottom: $margin-medium
        min-height: 0

        +at-max-width($bp-sales-figures)
            width: calc(#{columns-to-percent(1)} - #{$margin-medium})