.print-icon
    display: none

.cpq-footer-buttons
    width: 0

    > *
        display: none

.cpq-price
    width: 100vw

.cpq-main-content
    height: auto

.cpq-footer
    box-shadow: none

.cpq-page-content,
.cpq-page
    height: auto
    display: block

.cpq-page
    overflow-y: visible