.contact

    label
        color: $color-grey-medium
        font-style: italic
        margin: 0

    p
        margin: 0
        padding: 0 2px
        color: $color-grey-dark
        display: block
        text-decoration: none

    .column
        padding: 1rem 3rem 0 1rem
        margin: 0

    .data-pair
        text-decoration: none
        padding: 0
        margin: 0

    .department
        @extend %box-default
        margin: 3rem 0
        padding: 0 0 10px

        .error-info
            margin: 1rem 0 0 1rem

    .department-title
        background-color: #000
        text-transform: uppercase
        color: #fff
        width: 100%
        padding: 1rem
        font-weight: 500
        margin: 0

    .department-body
        display: flex
        justify-content: flex-start
        padding: 1rem 0

        & :first-child
            flex: 1

        & :last-child
            flex: 4
