.sales-figures
    display: flex
    flex-wrap: wrap
    margin-bottom: $margin-medium

    h2
        margin-top: 0
        font-size: 19px

    +at-max-width($bp-sales-figures)
        flex-direction: column

.sales-chart
    width: calc(100% - (400px + #{$margin-medium}))
    margin-left: $margin-medium

    +at-max-width($bp-sales-figures)
        flex: 0 1 auto
        width: 100%
        margin-left: 0

    .ct-bar
        stroke-width: 15px

    .ct-series-a
        .ct-bar
            stroke: $color-chart-current-year

    .ct-series-b
        .ct-bar
            stroke: $color-chart-previous-year

.sales-items
    display: flex

.sales-item,
.sales-chart
    @extend %box-default

.sales-contura
    +at-max-width($bp-sales-figures)
        margin-left: $margin-medium

.sales-total
    margin-bottom: $margin-medium

    +at-max-width($bp-sales-figures)
        margin-bottom: 0

.sales-price
    font-size: 30px

    .unit
        display: inline-block
        margin-left: 0.2em
        font-size: 0.9em

.sales-chart-footer
    display: flex
    justify-content: center
    align-items: center

    .chart-color
        display: inline-block
        width: 15px
        height: 15px
        margin-right: 5px

.chart-year
    display: flex
    justify-content: center
    align-items: center
    margin: 0 10px

.chart-year-current
    .chart-color
        background-color: $color-chart-current-year

.chart-year-previous
    .chart-color
        background-color: $color-chart-previous-year