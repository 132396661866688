.txt-loading
    position: relative

    span
        visibility: hidden

    &:after
        content: ' '
        position: absolute
        top: 50%
        left: 50%
        width: 20px
        height: 20px
        margin-top: -10px
        margin-left: -10px
        border-top: 2px solid rgba(240, 109, 34, 0.3)
        border-right: 2px solid rgba(240, 109, 34, 0.3)
        border-bottom: 2px solid rgba(240, 109, 34, 0.3)
        border-left: 2px solid rgba(240, 109, 34, 1)
        transform: translateZ(0)
        animation: loading-spinner 1s infinite linear
        border-radius: 50% 