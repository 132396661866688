$confirm-msg-width: 500px

.confirm-message-block
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    display: none
    justify-content: center
    align-items: center

.has-visible-confirm-message
    .toggle-shopping-cart
        display: none

    .confirm-message-block
        display: flex

.confirm-message-overlay
    background-color: $overlay-backdrop-color
    backdrop-filter: $overlay-backdrop-filter
    z-index: 11
    transition: opacity 0.7s
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0

.confirm-message
    position: fixed
    z-index: 12
    width: $confirm-msg-width
    left: calc(50% - calc($confirm-msg-width / 2))
    max-width: calc(100% - 60px)
    background-color: #fff
    padding: 50px

    h3
        margin: 0

.confirm-buttons
    .button
        margin: 0

.confirm-message-close
    position: absolute
    top: 10px
    right: 10px
    cursor: pointer

    line
        transition: stroke 0.2s ease

    .icon
        width: 30px
        height: 30px

    &:hover
        line
            stroke: $color-brand-orange