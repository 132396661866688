.page-content-login
    h1, h2, p
        color: #fff

    h2
        margin-top: 80px

    .main-container
        width: 100%
        background-color: #000

    main
        display: flex
        justify-content: center
        align-items: center

        height: 100vh
        max-width: 100vw
        padding: 30px

    .main-content
        max-width: 850px
        text-align: center
        padding: 0

.login-logo
    width: 250px
    margin: 0 auto 90px

    svg
        fill: #fff

.fieldset-login
    max-width: 340px
    margin: 0 auto

    .field
        width: 100%