$sign-out-msg-width: 500px

.sign-out-message-block
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    display: none
    justify-content: center
    align-items: center

.has-visible-sign-out-message
    .toggle-shopping-cart
        display: none

    .sign-out-message-block
        display: flex

    .user-menu
        display: none

.sign-out-message-overlay
    background-color: rgba(0, 0, 0, 0.2)
    z-index: 11
    transition: opacity 0.7s
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    backdrop-filter: saturate(170%) blur(4px)

.sign-out-message
    position: fixed
    z-index: 12
    width: $sign-out-msg-width
    left: calc(50% - calc($sign-out-msg-width / 2))
    max-width: calc(100% - 60px)
    background-color: #fff
    padding: 50px

    h3
        margin: 0

.confirm-buttons
    .button
        margin: 0

.sign-out-message-close
    position: absolute
    top: 10px
    right: 10px
    cursor: pointer

    line
        transition: stroke 0.2s ease

    .icon
        width: 30px
        height: 30px

    &:hover
        line
            stroke: $color-brand-orange