%button
    padding: 10px 25px
    font-size: 1rem
    border-radius: 25px
    border-style: none
    cursor: pointer
    outline: 0
    margin: 70px auto
    display: inline-block
    letter-spacing: 0.05em
    text-decoration: none
    transition: color 0.2s ease, background-color 0.2s ease
    text-align: center

    &:hover
        background-color: #eeeeee
        text-decoration: none

    &[disabled]
        cursor: default

%button-primary
    @extend %button
    background-color: $color-brand-orange
    color: #fff
    text-transform: uppercase

    &:hover
        background-color: darken($color-brand-orange, 5%)

%button-secondary
    @extend %button
    background-color: $color-grey-dark
    color: #fff
    text-transform: uppercase

    &:hover:not([disabled])
        background-color: darken($color-grey-dark, 10%)

%button-tertiary
    @extend %button
    background-color: #fff
    color: $color-font-body

    &:hover
        color: $color-brand-orange

.button-delete
    @extend %button
    background-color: $color-delete
    color: #fff

    &:hover:not([disabled])
        background-color: darken($color-delete, 5%)

.button
    @extend %button

.button-primary
    @extend %button-primary

.button-secondary
    @extend %button-secondary

.button-tertiary
    @extend %button-tertiary

.button-sm
    padding: 0.4em 1.2em
    font-size: 16px
    border-radius: 16px
    margin: 15px auto 0
    text-transform: none

.button-loading
    position: relative

    span
        visibility: hidden

    &:after
        content: ' '
        position: absolute
        top: 50%
        left: 50%
        margin-top: -10px
        margin-left: -10px
        width: 20px
        height: 20px
        border-top: 2px solid rgba(255, 255, 255, 0.4)
        border-right: 2px solid rgba(255, 255, 255, 0.4)
        border-bottom: 2px solid rgba(255, 255, 255, 0.4)
        border-left: 2px solid rgba(255, 255, 255, 1)
        transform: translateZ(0)
        animation: loading-spinner 1s infinite linear
        border-radius: 50%

.buttonset
    justify-content: center

    .button-sm
        border-radius: 0
        margin-bottom: 0
        margin-left: 0
        margin-right: 0

        &:first-of-type
            border-top-left-radius: 16px
            border-bottom-left-radius: 16px

        &:last-of-type
            border-top-right-radius: 16px
            border-bottom-right-radius: 16px

.buttonset-confirm
    .buttonset
        display: none

    .button-tertiary
        box-shadow: inset 0 0 0 1px $color-grey-light

.buttonset-confirm-show-confirm
    > button
        display: none

    .buttonset
        display: flex

.buttongroup
    display: flex
    justify-content: space-between
    margin-top: $margin-large

    button
        margin: 0

.btn-loading
    position: relative

    span
        visibility: hidden

    &:after
        content: ' '
        position: absolute
        top: 50%
        left: 50%
        width: 20px
        height: 20px
        margin-top: -10px
        margin-left: -10px
        border-top: 2px solid rgba(255, 255, 255, 0.3)
        border-right: 2px solid rgba(255, 255, 255, 0.3)
        border-bottom: 2px solid rgba(255, 255, 255, 0.3)
        border-left: 2px solid rgba(255, 255, 255, 1)
        transform: translateZ(0)
        animation: loading-spinner 1s infinite linear
        border-radius: 50%


