.news-block
    display: flex

.news-archive
    width: 150px
    margin-left: 30px

.list-newsarchive-title
    margin: 0 0 1em

    &:hover
        cursor: pointer

.list-newsarchive-links
    margin-bottom: 30px

.list-newsarchive-link
    display: block
    margin-bottom: 0.3em
    color: $color-link
    text-decoration: none

    &:hover
        cursor: pointer

.news-content
    width: calc(100% - (150px + 30px))

.news-content-single-post
    display: none

.list-news-title
    margin: 0 0 1em
    transition: color 0.3s ease

.list-news-date
    display: block
    margin-bottom: 1em

.list-news-item
    display: block
    text-decoration: none
    color: $color-font-body
    margin-bottom: $margin-large

    &:hover
        .list-news-title
            color: $color-brand-orange

.list-news-excerpt
    &:after
        content: '...'

.news-block-show-single-post
    .news-content-latest-posts
        display: none

    .news-content-single-post
        display: block

//News Article
.news-article
    width: 70%
    padding: 0 0 200px

    ol, ul
        width: 100%

    li
        padding: 1.2rem 1rem
        border-bottom: 1px solid $color-grey-light

    h2, h3, h4, h5, h6
        margin: 2rem 0

.article-component

    width: 100%
    margin: 3rem 0

.article-image

    img
        display: block
        margin: 0 auto
        width: auto
        max-width: 70%
        max-height: 600

.article-abstract
    padding: 0 10%
    font-size: 1.5rem

.article-content
    padding: 0 10%
    font-size: 1.1rem

    p
        display: block
        width: 100%

.article-youtube
    position: relative
    overflow: hidden
    width: 100%
    padding-top: 56.25%

    iframe
        position: absolute
        top: 0
        left: 0
        bottom: 0
        right: 0
        width: 100%
        height: 100%

@media screen and ( min-width: 2000px )

    .article-youtube
        width: 100%
        max-height: 500px

    iframe
        margin: 0 auto
        max-width: 1000px
        height: 500px
        right: 5%
