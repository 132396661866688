.react-confirm-alert-body-element
    overflow: hidden

.react-confirm-alert-overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 99
    background-color: rgba(0, 0, 0, 0.2)
    backdrop-filter: saturate(170%) blur(4px)
    display: flex
    justify-content: center
    align-items: center
    opacity: 0
    animation: react-confirm-alert-fadeIn 0.2s 0.2s forwards

.react-confirm-alert-body
    max-width: 500px
    padding: 50px
    text-align: left
    background-color: #fff
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13)
    position: relative

    >
        h3
            margin-top: 0
            font-size: 16px


.react-confirm-alert-buttons
    display: flex
    justify-content: space-between
    margin-top: 20px
    padding: 30px 0 0

.react-confirm-alert-button
    margin: 0

.react-confirm-alert-close
    position: absolute
    top: 10px
    right: 10px
    cursor: pointer

    line
        transition: stroke 0.2s ease

    svg
        width: 30px
        height: 30px

    &:hover
        line
            stroke: $color-brand-orange


@keyframes react-confirm-alert-fadeIn
    from
        opacity: 0

    to
        opacity: 1
