@import 'settings'

@mixin ct-responsive-svg-container($width: 100%, $ratio: $ct-container-ratio)
    display: block
    position: relative
    width: $width

    &:before
        display: block
        float: left
        content: ' '
        width: 0
        height: 0
        padding-bottom: $ratio * 100%

    &:after
        content: ' '
        display: table
        clear: both

    > svg
        display: block
        position: absolute
        top: 0
        left: 0

@mixin ct-align-justify($ct-text-align: $ct-text-align, $ct-text-justify: $ct-text-justify)
    align-items: $ct-text-align
    justify-content: $ct-text-justify

    // Fallback to text-align for non-flex browsers
    @if($ct-text-justify == 'flex-start')
        text-align: left
    @else if ($ct-text-justify == 'flex-end')
        text-align: right
    @else
        text-align: center

@mixin ct-flex
    display: flex

@mixin ct-chart-label($ct-text-color: $ct-text-color, $ct-text-size: $ct-text-size, $ct-text-line-height: $ct-text-line-height)
    fill: $ct-text-color
    color: $ct-text-color
    font-size: $ct-text-size
    line-height: $ct-text-line-height

@mixin ct-chart-grid($ct-grid-color: $ct-grid-color, $ct-grid-width: $ct-grid-width, $ct-grid-dasharray: $ct-grid-dasharray)
    stroke: $ct-grid-color
    stroke-width: $ct-grid-width

    @if ($ct-grid-dasharray)
        stroke-dasharray: $ct-grid-dasharray

@mixin ct-chart-point($ct-point-size: $ct-point-size, $ct-point-shape: $ct-point-shape)
    stroke-width: $ct-point-size
    stroke-linecap: $ct-point-shape

@mixin ct-chart-line($ct-line-width: $ct-line-width, $ct-line-dasharray: $ct-line-dasharray)
    fill: none
    stroke-width: $ct-line-width

    @if ($ct-line-dasharray)
        stroke-dasharray: $ct-line-dasharray

@mixin ct-chart-area($ct-area-opacity: $ct-area-opacity)
    stroke: none
    fill-opacity: $ct-area-opacity

@mixin ct-chart-bar($ct-bar-width: $ct-bar-width)
    fill: none
    stroke-width: $ct-bar-width

@mixin ct-chart-donut($ct-donut-width: $ct-donut-width)
    fill: none
    stroke-width: $ct-donut-width

@mixin ct-chart-series-color($color)
    .#{$ct-class-point}, .#{$ct-class-line}, .#{$ct-class-bar}, .#{$ct-class-slice-donut}
        stroke: $color

    .#{$ct-class-slice-pie}, .#{$ct-class-slice-donut-solid}, .#{$ct-class-area}
        fill: $color

@mixin ct-chart($ct-container-ratio: $ct-container-ratio, $ct-text-color: $ct-text-color, $ct-text-size: $ct-text-size, $ct-grid-color: $ct-grid-color, $ct-grid-width: $ct-grid-width, $ct-grid-dasharray: $ct-grid-dasharray, $ct-point-size: $ct-point-size, $ct-point-shape: $ct-point-shape, $ct-line-width: $ct-line-width, $ct-bar-width: $ct-bar-width, $ct-donut-width: $ct-donut-width, $ct-series-names: $ct-series-names, $ct-series-colors: $ct-series-colors)
    .#{$ct-class-label}
        @include ct-chart-label($ct-text-color, $ct-text-size)

    .#{$ct-class-chart-line} .#{$ct-class-label},
    .#{$ct-class-chart-bar} .#{$ct-class-label}
        @include ct-flex

    .#{$ct-class-chart-pie} .#{$ct-class-label},
    .#{$ct-class-chart-donut} .#{$ct-class-label}
        dominant-baseline: central

    .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-start}
        @include ct-align-justify(flex-end, flex-start)
        // Fallback for browsers that don't support foreignObjects
        text-anchor: start

    .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-end}
        @include ct-align-justify(flex-start, flex-start)
        // Fallback for browsers that don't support foreignObjects
        text-anchor: start

    .#{$ct-class-label}.#{$ct-class-vertical}.#{$ct-class-start}
        @include ct-align-justify(flex-end, flex-end)
        // Fallback for browsers that don't support foreignObjects
        text-anchor: end

    .#{$ct-class-label}.#{$ct-class-vertical}.#{$ct-class-end}
        @include ct-align-justify(flex-end, flex-start)
        // Fallback for browsers that don't support foreignObjects
        text-anchor: start

    .#{$ct-class-chart-bar} .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-start}
        @include ct-align-justify(flex-end, center)
        // Fallback for browsers that don't support foreignObjects
        text-anchor: start

    .#{$ct-class-chart-bar} .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-end}
        @include ct-align-justify(flex-start, center)
        // Fallback for browsers that don't support foreignObjects
        text-anchor: start

    .#{$ct-class-chart-bar}.#{$ct-class-horizontal-bars} .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-start}
        @include ct-align-justify(flex-end, flex-start)
        // Fallback for browsers that don't support foreignObjects
        text-anchor: start

    .#{$ct-class-chart-bar}.#{$ct-class-horizontal-bars} .#{$ct-class-label}.#{$ct-class-horizontal}.#{$ct-class-end}
        @include ct-align-justify(flex-start, flex-start)
        // Fallback for browsers that don't support foreignObjects
        text-anchor: start

    .#{$ct-class-chart-bar}.#{$ct-class-horizontal-bars} .#{$ct-class-label}.#{$ct-class-vertical}.#{$ct-class-start}
        //@include ct-chart-label($ct-text-color, $ct-text-size, center, $ct-vertical-text-justify)
        @include ct-align-justify(center, flex-end)
        // Fallback for browsers that don't support foreignObjects
        text-anchor: end

    .#{$ct-class-chart-bar}.#{$ct-class-horizontal-bars} .#{$ct-class-label}.#{$ct-class-vertical}.#{$ct-class-end}
        @include ct-align-justify(center, flex-start)
        // Fallback for browsers that don't support foreignObjects
        text-anchor: end

    .#{$ct-class-grid}
        @include ct-chart-grid($ct-grid-color, $ct-grid-width, $ct-grid-dasharray)

    .#{$ct-class-grid-background}
        fill: $ct-grid-background-fill

    .#{$ct-class-point}
        @include ct-chart-point($ct-point-size, $ct-point-shape)

    .#{$ct-class-line}
        @include ct-chart-line($ct-line-width)

    .#{$ct-class-area}
        @include ct-chart-area

    .#{$ct-class-bar}
        @include ct-chart-bar($ct-bar-width)

    .#{$ct-class-slice-donut}
        @include ct-chart-donut($ct-donut-width)

    @if $ct-include-colored-series
        @for $i from 0 to length($ct-series-names)
            .#{$ct-class-series}-#{nth($ct-series-names, $i + 1)}
                $color: nth($ct-series-colors, $i + 1)

                @include ct-chart-series-color($color)

@if $ct-include-classes
    @include ct-chart

    @if $ct-include-alternative-responsive-containers
        @for $i from 0 to length($ct-scales-names)
            .#{nth($ct-scales-names, $i + 1)}
                @include ct-responsive-svg-container($ratio: nth($ct-scales, $i + 1))


