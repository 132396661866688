.list-product
    img
        display: block

.list-product-items
    display: flex
    flex-wrap: wrap
    margin-left: -30px

.list-product-item
    width: calc(25% - 30px)
    margin-left: 30px
    margin-bottom: 30px
    background-color: #fff
    box-shadow: $box-shadow-light
    position: relative
    display: flex
    flex-direction: column

    +at-max-width(1400px)
        width: calc(50% - 30px)

.list-product-item-image
    display: flex
    justify-content: center
    align-items: center
    padding-top: 10px

.list-product-item-select-live-display
    margin-left: -10px

    label
        display: inline-block

    .field-checkbox:checked + label
        &:before
            content: ' '
            display: inline-block
            width: 12px
            height: 7px
            border-left: 2px solid #fff
            border-bottom: 3px solid #fff
            margin-right: 7px
            margin-bottom: 2px
            transform: rotate(-45deg)

.list-product-item-content
    text-align: center
    padding: 15px 10px 25px
    display: flex
    flex-direction: column
    flex: 1 0 auto

.list-product-item-title
    font-size: 16px
    margin-bottom: 0
    margin-top: 0

.list-product-item-subtitle
    font-size: 16px
    margin-top: 10px

.icon-live-display
    position: absolute
    display: none
    top: 26px
    right: 10px

    svg
        fill: $color-brand-orange
        width: 50px
        display: block

.list-product-item-is-live-display
    .icon-live-display
        display: block

.list-product-item-actions
    .buttonset-confirm
        display: none

.list-product-item-loading
    .button-add,
    .button-delete
        position: relative

        span
            visibility: hidden

        &:after
            content: ' '
            position: absolute
            top: 50%
            left: 50%
            margin-top: -10px
            margin-left: -10px
            width: 20px
            height: 20px
            border-top: 2px solid rgba(255, 255, 255, 0.4)
            border-right: 2px solid rgba(255, 255, 255, 0.4)
            border-bottom: 2px solid rgba(255, 255, 255, 0.4)
            border-left: 2px solid rgba(255, 255, 255, 1)
            transform: translateZ(0)
            animation: loading-spinner 1s infinite linear
            border-radius: 50%

.list-product-item-in-showroom
    .button-add
        display: none

    .buttonset-confirm
        display: block

.list-product-items-add
    .list-product-item-in-showroom
        position: relative
        box-shadow: 0 0 0 4px green

        &:after
            content: attr(data-title)
            position: absolute
            top: 0
            left: 0
            right: 0
            font-size: 14px
            padding-bottom: 4px
            background-color: green
            text-align: center
            color: #fff

        .list-product-item-actions
            position: relative

            &:after
                content: ' '
                display: block
                background-image: url('../../images/icons/check-mark-button.svg')
                background-size: 34px 34px
                background-repeat: no-repeat
                background-position: center top
                position: absolute
                top: 0
                right: 0
                bottom: 0
                left: 0
                filter: invert(19%) sepia(100%) saturate(4018%) hue-rotate(108deg) brightness(97%) contrast(104%)

        .list-product-item-subtitle,
        .list-product-item-select-live-display,
        button,
        .buttonset-confirm
            visibility: hidden


//.field-is-live-display-yes
    &:checked
        + label
            background-color: $color-brand-orange
            border-color: $color-brand-orange