$header-height: 60px
$cpq-footer-height: 90px
$cpq-tabs-height: 43px
$cpq-image-width: 30%
$checkbox-width: 30px
$screen-column-left-width: 200px
$color-form: #e5e5e5
$color-form-active: $color-brand-orange

.cpq-main-content
    height: calc(100vh - #{$header-height})
    display: flex
    flex-direction: column
    position: relative

.cpq-page-content
    height: calc(100vh - #{$header-height + $cpq-footer-height})
    display: flex

.cpq-pages
    height: calc(100vh - #{$header-height + $cpq-footer-height})
    width: calc(100% - #{$cpq-image-width})
    display: flex
    flex-direction: column
    position: relative

.cpq-page
    display: none
    height: calc(100vh - #{$header-height + $cpq-footer-height})
    width: 100%
    padding: 40px
    overflow-y: auto

.cpq-secondary-content
    width: $cpq-image-width
    background-color: #fff
    //visibility: hidden

.cpq-page--active
    display: block

.cpq-config-image
    width: 100%
    position: sticky
    top: 0
    text-align: center

    img
        max-height: calc(100vh - 200px)

.cpq-option-info
    margin-left: 10px
    flex-shrink: 0

    svg
        width: 20px
        height: 20px
        transition: fill 0.2s ease

        &:hover
            fill: $color-brand-orange

        &:active
            fill: darken($color-brand-orange, 10%)

//.cpq-page-content-has-image
//    .cpq-secondary-content
//        visibility: visible

.cpq-option-row
    display: flex
    align-items: flex-start

    + .cpq-option-row
        margin-top: 10px

.cpq-option-caption
    width: $screen-column-left-width
    display: flex
    align-items: flex-start
    padding-top: 0.2em

.cpq-option-input
    width: calc(100% - #{$screen-column-left-width})
    padding-left: 20px

.cpq-footer
    height: #{$cpq-footer-height}
    //padding: 20px calc(#{$cpq-image-width} + 40px) 20px 40px
    padding: 20px 40px
    display: flex
    justify-content: space-between
    align-items: center
    background-color: #fff
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.15)
    position: relative
    z-index: 2

    button
        margin: 0

.cpq-footer-buttons
    display: flex
    gap: 20px // sass-lint:disable-line no-misspelled-properties
    justify-content: space-between
    width: calc(100% - #{$cpq-image-width} - 20px)
    margin-bottom: 15px

.cpq-price
    margin: 0
    width: calc(30% - 40px)
    display: flex
    flex-direction: column
    justify-content: center

.cpq-price-details
    display: flex
    flex-wrap: wrap
    gap: 20px // sass-lint:disable-line no-misspelled-properties
    justify-content: flex-end

.cpq-incl-vat
    display: block
    font-size: 12px
    text-align: right

.cpq-price-label,
.cpq-price-value
    font-size: 20px
    letter-spacing: 0.05em
    font-family: 'droid_sansbold', sans-serif
    text-transform: uppercase

.cpq-price-label
    &:after
        content: ':'

.cpq-price-value
    white-space: nowrap

.cpq-select-box,
.cpq-input-field
    background-color: #fff
    padding: 12px 12px 12px 20px
    font-size: 1rem
    border-radius: 25px
    border-style: none
    display: inline-block

.cpq-input-field
    min-width: 250px

.cpq-radio-label,
.cpq-checkbox-label
    margin-left: 0
    margin-bottom: 10px
    padding-top: 6px
    padding-left: 40px
    line-height: 1.2em
    min-height: 30px
    height: auto
    cursor: pointer
    position: relative

    &:before,
    &:after
        content: ' '
        position: absolute
        height: 30px
        width: 30px
        transition: border 250ms, background-color 250ms, transform 250ms

.cpq-checkbox-label
    &:before
        transform: scale(0)

    &:after
        background-color: #fff
        border: 2px solid $color-form
        top: 0
        left: 0
        z-index: 1
        border-radius: 3px

.cpq-radio-label
    display: inline-block

    &:before
        top: 0
        left: 0
        border: 2px solid $color-form
        transform-origin: 100% 100%
        background-color: #fff
        z-index: 1
        border-radius: 50%
        transform: scale(1)

    &:after
        background-color: transparent
        border-radius: 50%
        border: 2px solid $color-form
        top: 0
        left: 0
        z-index: 1
        transform: scale(0)

    .value
        &:before
            content: '('

        &:after
            content: ')'

.cpq-checkbox-field,
.cpq-radio-field
    display: none

    &:checked
        + .cpq-checkbox-label
            &:before
                top: 6px
                left: 5px
                width: 10px
                height: 15px
                border-top: 2px solid transparent
                border-left: 2px solid transparent
                border-right: 2px solid #fff
                border-bottom: 2px solid #fff
                transform: rotateZ(37deg)
                transform-origin: 100% 100%
                z-index: 1

            &:after
                top: 0
                border: 2px solid $color-form-active
                background-color: $color-form-active
                z-index: 0
                box-shadow: none

        + .cpq-radio-label
            &:before
                top: 0
                left: 0
                border: 2px solid $color-form-active
                background-color: #fff
                transform-origin: 100% 100%
                z-index: 0
                border-radius: 50%

            &:after
                border: 2px solid $color-form-active
                background-color: $color-form-active
                z-index: 1
                transform: scale(0.5)

    &:disabled
        + .cpq-checkbox-label
            cursor: default

            &:after
                background-color: #e5e5e5

        + .cpq-radio-label
            cursor: default
            opacity: 0.5

            &:before
                background-color: $color-form

.cpq-radio-controls-horizontal-center
    display: flex
    flex-wrap: wrap
    gap: 0 30px // sass-lint:disable-line no-misspelled-properties
    justify-content: center

.cpq-select-box
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDMzMCAzMzAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMzMCAzMzA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGlkPSJYTUxJRF8yMjVfIiBkPSJNMzI1LjYwNyw3OS4zOTNjLTUuODU3LTUuODU3LTE1LjM1NS01Ljg1OC0yMS4yMTMsMC4wMDFsLTEzOS4zOSwxMzkuMzkzTDI1LjYwNyw3OS4zOTMNCgljLTUuODU3LTUuODU3LTE1LjM1NS01Ljg1OC0yMS4yMTMsMC4wMDFjLTUuODU4LDUuODU4LTUuODU4LDE1LjM1NSwwLDIxLjIxM2wxNTAuMDA0LDE1MGMyLjgxMywyLjgxMyw2LjYyOCw0LjM5MywxMC42MDYsNC4zOTMNCglzNy43OTQtMS41ODEsMTAuNjA2LTQuMzk0bDE0OS45OTYtMTUwQzMzMS40NjUsOTQuNzQ5LDMzMS40NjUsODUuMjUxLDMyNS42MDcsNzkuMzkzeiIvPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=')
    background-repeat: no-repeat
    background-size: 16px 16px
    background-position: calc(100% - 20px) 50%
    padding-right: 55px

.cpq-screen
    padding-bottom: 20px
    padding-top: 20px
    border-bottom: 1px solid #e5e5e5

    &:empty
        border-style: none
        padding: 0
        margin: 0

.cpq-option-caption-text-required
    &:after
        content: '*'
        color: $color-brand-orange
        padding-left: 4px

.cpq-radio-control-groups,
.cpq-radio-control-with-image-groups
    display: flex
    flex-wrap: wrap

    .cpq-radio-control
        display: flex
        margin: 0 50px 20px 0

.cpq-radio-control-groups
    .cpq-radio-control
        label
            margin: 0 0 0 5px

.cpq-radio-control-with-image-groups
    .cpq-radio-control
        position: relative

    .cpq-radio-label
        position: absolute
        top: 10px
        left: 10px

    .cpq-radio-label-text
        display: block
        margin-top: 5px
        text-align: center

    .cpq-image-label
        background-color: #fff
        padding: 40px
        margin: 0
        display: flex
        flex-direction: column-reverse

    .cpq-radio-field:checked
        ~ .cpq-image-label
            border: 2px solid $color-form-active

.cpq-info-tabs
    display: flex
    border-bottom: 4px solid $color-grey-x-light
    padding-left: 10px
    margin: 0 10px

.cpq-info-tab
    padding: 16px 10px 12px
    margin-right: 10px
    color: #4f4f4a
    letter-spacing: 0.05em
    margin-bottom: -4px
    position: relative
    text-align: center
    transition: color 0.2s ease

    &:hover
        color: $color-brand-orange

    &[disabled]
        display: none

.cpq-info-tab--active
    cursor: default
    color: #000
    font-weight: 700

    &:hover
        color: #000

    &:after
        content: ' '
        display: block
        position: absolute
        bottom: 0
        left: 10px
        width: calc(100% - 20px)
        height: 4px
        //z-index: 9999
        background-color: $color-grey-dark
        border-radius: 2px


@keyframes spin
    100%
        transform: rotate(360deg)


.cpq-info-panel
    display: none
    padding: 20px
    overflow-y: auto
    padding-bottom: 40px
    height: calc(100vh - #{$header-height + $cpq-footer-height + $cpq-tabs-height})

    h3
        margin: 3em 0 0

        &:first-child
            margin-top: 0

    h4
        font-family: 'droid_sansbold', sans-serif
        margin: 1.5em 0 0

    p
        margin: 0.7em 0 0
        line-height: 1.4em
        font-size: 14px

    ul
        font-size: 14px
        padding: 0
        list-style: none

    li
        margin-bottom: 0.3em
        padding-left: 10px
        position: relative

        &:before
            content: '\00B7'
            position: absolute
            top: 0
            left: 0

.cpq-info-panel--active
    display: block

.cpq-info-tab,
.cpq-progress-step
    font-size: 14px

.cpq-progress-steps
    display: flex
    flex-wrap: wrap
    margin: 0 30px 20px 40px
    padding-left: 10px
    border-bottom: 4px solid #fff

.cpq-progress-step
    text-align: center
    padding: 16px 10px 12px
    margin-right: 10px
    position: relative
    cursor: pointer
    margin-bottom: -4px

    &:hover
        color: $color-brand-orange

.cpq-progress-step-current
    cursor: default

    &:hover
        color: $color-font-body

    &:after
        content: ' '
        display: block
        position: absolute
        bottom: 0
        left: 10px
        width: calc(100% - 20px)
        height: 4px
        background-color: #343536
        border-radius: 2px

.cpq-screen-loader
    display: block
    height: 100px

.page-is-loading
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    backdrop-filter: saturate(170%) blur(3px)
    background-color: rgba(245, 245, 245, 0.93)
    display: flex
    justify-content: center
    align-items: center
    z-index: 9

.loading-spinner
    animation: rotate 2s linear infinite
    z-index: 2
    position: absolute
    top: 36%
    left: 50%
    margin: -25px 0 0 -25px
    width: 70px
    height: 70px

    .path
        stroke: $color-brand-orange
        stroke-linecap: round
        animation: dash 1.5s ease-in-out infinite

    +at-max-height(800px)
        top: 50%


@keyframes rotate
    100%
        transform: rotate(360deg)


@keyframes dash
    0%
        stroke-dasharray: 1, 150
        stroke-dashoffset: 0

    50%
        stroke-dasharray: 90, 150
        stroke-dashoffset: -35

    100%
        stroke-dasharray: 90, 150
        stroke-dashoffset: -124

