.list-category-item
    padding-bottom: 2px
    border-bottom: 1px solid silver

.list-category-title
    color: $color-brand-orange
    font-size: 20px
    padding: 20px 0
    cursor: pointer
    display: flex
    justify-content: space-between
    text-decoration: underline
    letter-spacing: 0.05em
    text-transform: uppercase

    .icon-toggle
        width: 26px
        height: 26px
        position: relative

        svg
            display: block

.list-category-item-loading
    .icon-toggle
        animation: spinner 0.6s linear infinite

        &:before
            content: ''
            box-sizing: border-box
            position: absolute
            top: 50%
            left: 50%
            width: 26px
            height: 26px
            margin-top: -13px
            margin-left: -13px
            border-radius: 50%
            border: 3px solid #ccc
            border-top-color: $color-brand-orange

        svg
            display: none

.list-category-content
    padding: 30px 40px 0
    display: none
    flex-direction: column

.list-category-content-empty
    padding: 0 40px 20px

.list-category-link
    display: block
    margin-bottom: 30px
    text-decoration: none
    color: $color-font-body
    text-transform: uppercase

.list-category-item-has-visible-content
    .list-category-content
        display: flex

    .icon-toggle, .icon-toggle--extended
        transform: rotate(180deg)

.page-content-home,
.page-content-media,
.page-content-media-dop,
.page-content-media-signs,
.page-content-media-manuals,
.page-content-media-campaign,
.page-content-media-high-resolution
    .list-category-items
        background-color: #fff

        &--extended
            background-color: transparent

    .list-category-item
        &:last-of-type
            border-style: none

    .icon-toggle
        .st0, .st1
            stroke: $color-font-body

    .list-category-title
        text-decoration: none
        color: $color-font-body

    .list-category-link
        text-decoration: underline

    .list-category-title,
    .list-category-content
        padding-left: 20px
        padding-right: 20px
