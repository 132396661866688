.page-content-cpq-loading

    h1, h2, p
        color: #fff

    h2
        margin-top: 80px

    .main-container
        width: 100%
        background-color: #000

    main
        display: flex
        justify-content: center
        align-items: center

        height: 100vh
        max-width: 100vw
        padding: 30px

    .main-content
        max-width: 850px
        text-align: center
        padding: 0

    .icon-loading
        svg
            fill: #fff
