.list-video
    img
        display: block

.list-video-items
    display: flex
    flex-wrap: wrap
    margin-left: -30px


.list-video-item
    width: calc(25% - 30px)
    margin-left: 30px
    margin-bottom: 30px
    background-color: #fff
    box-shadow: $box-shadow-light
    text-decoration: none
    color: $color-font-body


    +at-max-width(1600px)
        width: calc(50% - 30px)

    &:hover
        .icon-download
            animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both

    video
        max-width: 100%

.list-video-item-image
    display: flex
    justify-content: center
    align-items: center

.list-video-content
    text-align: center
    padding: 10px 10px 30px

.list-video-title
    margin-bottom: 10px

.list-video-meta
    margin: 0

.list-video-subtitle
    text-transform: uppercase
    color: $color-brand-orange
    margin-top: $margin-medium

.icon-download
    width: 30px
    fill: $color-brand-orange
