.site-header,
.navigation
    display: none

.page-content
    display: block
    height: auto

    .main-container
        width: auto
        background-color: transparent

html,
.page-container,
.page-content,
.main-container
    overflow-y: visible