.news-overlay-background
    position: fixed
    height: 100%
    width: 100%
    background-color: #cfcccc9a // sass-lint:disable-line no-invalid-hex
    z-index: 100
    display: block
    overflow-y: auto

.news-overlay-container
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.12)
    background-color: #fff
    padding: 30px
    position: relative
    left: 30%
    top: 10%
    width: 40%
    background-color: $color-grey-lighter
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

    +at-max-width(1200px)
        top: 10px
        left: 25%
        padding: 10px
        width: 50%
        min-height: 70%
        max-height: 90%

    +at-max-width(1000px)
        left: 20%
        width: 60%
    +at-max-width(800px)
        left: 15%
        width: 70%

.news-overlay-card
    display: flex
    width: 100%
    margin: auto 0
    align-items: center
    justify-content: center

    a, p
        color: $color-grey-dark
        text-decoration: none
        margin: 0 auto

.card-img
    width: 100%
    max-height: 700px

.card-title
    width: 100%

.close-btn
    @extend %button-primary
    margin-bottom: 10px
