html, body, .page-container, .page-content
    overflow: hidden

.page-container, .page-content
    height: 100vh
    width: 100vw

.main-container, .navigation
    overflow-y: scroll

.page-content
    display: flex
    flex-direction: row-reverse

    h1
        text-align: center

    .main-container
        background-color: $color-grey-x-light
        width: calc(100% - #{$width-navigation})

        +at-max-width($bp-mobile)
            width: 100%

.navigation
    width: $width-navigation

    +at-max-width($bp-mobile)
        position: fixed
        top: 0
        left: 0
        bottom: 0
        transform: translateX(-100%)
        z-index: 99999
        transition: transform 250ms cubic-bezier(0.2, 0.7, 0.4, 1)

.main-content
    padding: 70px
    max-width: 1600px

.overlay
    position: fixed
    top: 0
    left: 0
    bottom: 0
    background-color: $overlay-backdrop-color
    opacity: 0
    z-index: 10
    transition: opacity 0.7s
    backdrop-filter: $overlay-backdrop-filter

.has-visible-mobile-menu
    .main-container
        filter: blur(4px)

    .navigation
        transform: translateX(0)
        box-shadow: 5px 0 25px -7px rgba(0, 0, 0, 0.6)

    .overlay
        right: 0
        opacity: 1

.content-primary
    text-align: center

    h1, h2, h3, p
        text-align: center

.content-primary-boxed
    background-color: $color-grey-dark
    padding: 70px

    h1, h2, p
        color: #fff

.secondary-content,
.content-secondary
    padding-top: 70px

    form
        margin-top: 0

.hide
    display: none !important // sass-lint:disable-line no-important

.price
    .money,
    .currency
        padding-left: 4px
        font-size: 0.9em