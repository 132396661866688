@keyframes swing-in-top-fwd
    0%
        transform: rotateX(-100deg)
        transform-origin: top
        opacity: 0

    100%
        transform: rotateX(0deg)
        transform-origin: top
        opacity: 1

@keyframes jello-horizontal
    0%
        transform: scale3d(1, 1, 1)

    30%
        transform: scale3d(1.25, 0.75, 1)

    40%
        transform: scale3d(0.75, 1.25, 1)

    50%
        transform: scale3d(1.15, 0.85, 1)

    65%
        transform: scale3d(0.95, 1.05, 1)

    75%
        transform: scale3d(1.05, 0.95, 1)

    100%
        transform: scale3d(1, 1, 1)

@keyframes bounce-top
    0%
        transform: translateY(-20px)
        animation-timing-function: ease-in
        opacity: 1

    24%
        opacity: 1

    40%
        transform: translateY(-18px)
        animation-timing-function: ease-in

    65%
        transform: translateY(-10px)
        animation-timing-function: ease-in

    82%
        transform: translateY(-4px)
        animation-timing-function: ease-in

    93%
        transform: translateY(-2px)
        animation-timing-function: ease-in

    25%,
    55%,
    75%,
    87%
        transform: translateY(0)
        animation-timing-function: ease-out

    100%
        transform: translateY(0)
        animation-timing-function: ease-out
        opacity: 1

@keyframes scale-up-center
    0%
        transform: scale(1)

    100%
        transform: scale(1.2)

@keyframes singlebeat
    0%
        transform: scale(1)

    40%
        transform: scale(1.6)

    70%
        transform: scale(1)

@keyframes spinner
    to
        transform: rotate(360deg)

@keyframes rotation
    from
        transform: rotate(0deg)

    to
        transform: rotate(359deg)

@keyframes loading-spinner
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)