.edit-box
    margin-bottom: $margin-medium
    background-color: $color-grey-dark

    label
        color: #fff

.edit-box-btn-edit
    display: none

.edit-box-header
    display: flex
    justify-content: space-between

    color: #fff
    background-color: $color-grey-dark

.edit-box-title
    text-transform: uppercase
    padding: 20px

.edit-box-toggle-content
    text-decoration: underline
    display: flex
    justify-content: center
    align-items: center

    svg
        fill: #fff
        width: 24px
        transform: rotate(45deg)

.edit-box-content
    padding: 20px 20px 30px

    .field-select
        margin-bottom: 0

    .field-select-input
        margin-bottom: 0

.edit-box-btn
    cursor: pointer
    padding: 20px

    &:hover
        color: $color-link

.field-hidden
    display: none

.grid-item-edit-user
    color: #fff

.edit-box-disabled
    background-color: #fff

    .edit-box-toggle-content svg
        transform: rotate(0)

    .edit-box-btn-update
        display: none

    .edit-box-btn-edit
        display: block

    &.edit-box-new-account
        .edit-box-content
            display: none

    button
        display: none

    .form-new-account
        display: none

    .grid-item-edit-user
        color: $color-font-body

    .grid-item-user-disabled
        opacity: 0.5

    .field
        padding-top: 0
        padding-bottom: 0
        padding-left: 0

    table .field
        margin-bottom: 0


    label
        color: $color-font-body
        opacity: 0.5
        margin-left: 0

    [readonly]
        opacity: 1
