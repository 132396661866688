.breadcrumbs-items
    display: flex
    flex-wrap: wrap
    margin: 0 0 30px
    padding-left: 10px
    border-bottom: 4px solid #fff

.breadcrumbs-item-text
    color: $color-font-body
    background-color: transparent
    border-radius: 0
    padding: 0
    line-height: 1.3em

    &:hover
        text-decoration: none
        color: $color-brand-orange

.breadcrumbs-item
    text-align: center
    padding: 16px 10px 12px
    margin-right: 10px
    position: relative
    cursor: pointer
    margin-bottom: -4px

    &:hover
        color: $color-brand-orange

    &:last-child
        cursor: default

        .breadcrumbs-item-text
            &:hover
                color: $color-font-body

        &:after
            content: ' '
            display: block
            position: absolute
            bottom: 0
            left: 10px
            width: calc(100% - 20px)
            height: 4px
            background-color: #343536
            border-radius: 2px