+font-face('droid_sansregular', 'normal', 'normal', '../../fonts/DroidSans-webfont.woff', 'woff')
+font-face('droid_sansbold', 'normal', 'normal', '../../fonts/DroidSans-Bold-webfont.woff', 'woff')

body, textarea, input, select, button
    font-family: $font-family-body
    font-size: 16px
    font-weight: normal
    color: $color-font-body

body
    margin: 0

h1, h2, h3
    text-transform: uppercase
    word-wrap: break-word

h1
    font-size: 30px
    margin-top: 0
    margin-bottom: 20px
    letter-spacing: 0.05em

    +at-max-width(500px)
        font-size: 24px

h2
    font-size: 20px
    margin-top: 1.4em
    margin-bottom: 20px
    letter-spacing: 0.05em

    +at-max-width(500px)
        font-size: 18px

h3
    font-size: 16px
    margin-top: 1.4em
    margin-bottom: 20px
    letter-spacing: 0.05em

p
    max-width: 55em

.page-content-default
    h1, h2
        text-align: center

.content-primary
    p, h3
        margin-left: auto
        margin-right: auto
        text-align: center

%table th,
.price .value,
%button,
.tfoot-show-more,
.menu-item-current > .menu-item-link,
.field-label,
b,
h3,
.cart-summary-grandtotal
    @extend %bold-font

img
    max-width: 100%
    height: auto

.button-sm
    @extend %normal-font

.error-message
    color: #fff
    background-color: red
    padding: 0.4em 0.5em
    border-radius: 3px

.error-info
    font-size: 12px
    display: inline-flex
    align-items: center
    line-height: 1.2em
    color: #555
    //margin: 1em

    svg
        width: 16px
        height: 16px
        margin-right: 3px
        fill: $color-brand-orange

a, .fake-link
    color: $color-brand-orange
    text-decoration: none
    cursor: pointer

    &:hover
        text-decoration: underline


