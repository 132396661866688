.selected-news
    width: 100%
    padding: 30px 0
    display: flex
    justify-content: space-between
    gap: 10px // sass-lint:disable-line no-misspelled-properties
    max-height: 400px

.news-card
    @extend %box-default
    width: 100%
    text-decoration: none
    padding: 10px
    display: flex
    justify-content: space-between
    flex-direction: column

    &:hover
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.24)
        text-decoration: none

.card-img
    display: block
    width: auto
    height: 80%
    //max-height:
    padding: 0 2px
    margin: 0

.card-title
    color: #343536
    text-align: center
    width: 100%
    padding: 5px
    margin: 0
