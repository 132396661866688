.navigation
    background-color: #000
    color: $color-menu-item
    padding: 30px

.navigation-header
    display: flex
    justify-content: space-between
    margin-bottom: 65px

    .toggle-mobile-menu-icon span,
    .toggle-mobile-menu-icon span:after,
    .toggle-mobile-menu-icon span:before
        background-color: #fff

    .toggle-mobile-menu
        display: none

        &:hover
            .toggle-mobile-menu-icon span:after,
            .toggle-mobile-menu-icon span:before
                background-color: $color-brand-orange

        +at-max-width($bp-mobile)
            display: block

.navigation-logo
    width: 110px

    svg
        fill: #fff

    &:hover
        svg
            fill: $color-brand-orange

.menu
    text-transform: uppercase

.menu-item
    position: relative

.menu-item-link--level-1

    .icon-wrap
        width: 25px
        height: 25px
        display: flex
        margin-right: 10px
        align-items: center

    .icon
        fill: $color-menu-item
        max-width: 100%
        max-height: 100%

.menu-item-link
    color: $color-menu-item
    text-decoration: none
    padding: 10px 0
    display: flex
    align-items: center
    cursor: pointer
    transition: color 0.2s ease

    &:hover
        text-decoration: none
        color: #fff

.menu-item--has-children
    padding-right: 50px

.menu-toggle-child
    width: 40px
    height: 30px
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    top: 7px
    right: -15px
    cursor: pointer

    &:after
        content: ' '
        +css-arrow(bottom, 5px, 1px, $color-menu-item)
        transform-origin: 75% 75%

.menu--level-2
    margin-left: 35px
    display: none

.menu-item-current
    .menu-item-link
        color: #fff

        svg
            fill: #fff

    .menu-toggle-child:after
        border-color: #fff
        transform: rotate(-135deg)

    .menu--level-2
        display: block


