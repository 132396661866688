.content-primary-feedback
    display: none

.complaints-submitted
    .content-primary
        display: none

    .content-primary-feedback
        display: block

.complaint-article-items
    background-color: #fff
    margin-bottom: 20px

    .product-quantity
        .label
            display: none

.complaint-article-item-header
    background-color: #000
    color: #fff

.complaint-article-item
    display: grid
    grid-template-columns: 20% auto 10% 120px

    span
        padding: 1.2em 1em

        &:nth-of-type(3)
            justify-self: end

.complaint-article-item-data
    span
        padding-top: 25px

        &:nth-of-type(4)
            padding-top: 1.2em

.complaints-feedback
    p
        white-space: pre-line
        line-height: 1.6em

.complaints-image-title
    color: $color-link
    cursor: pointer

    &:hover
        text-decoration: underline

.field-file-placeholder,
.complaints-images
    ol
        list-style: decimal
        margin: 0
        padding: 0 0 0 1.8em

    li
        margin: 0 0 0.5em
        line-height: 1.3em
        hyphens: auto
        word-break: break-all

.complaints-image-spinner
    animation: rotate 2s linear infinite
    width: 20px
    height: 20px

    .path
        stroke: $color-brand-orange
        stroke-linecap: round
        animation: dash 0.5s ease-in-out infinite

.complaints-image
    display: inline-flex
    align-items: center
    gap: 10px // sass-lint:disable-line no-misspelled-properties

.complaints-image-spinner
    display: none

.complaints-image-loading
    .complaints-image-spinner
        display: inline-block