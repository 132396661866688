.site-header
    height: 60px
    box-shadow: $box-shadow-light
    background-color: #fff
    position: sticky
    top: 0
    display: flex
    justify-content: space-between
    align-items: center
    padding-left: 70px
    padding-right: 70px
    z-index: 10

    .toggle-mobile-menu
        margin-right: 10px

        &:hover
            .toggle-mobile-menu-icon span,
            .toggle-mobile-menu-icon span:after,
            .toggle-mobile-menu-icon span:before
                background-color: $color-brand-orange

.site-header-left,
.site-header-right
    display: flex
    flex: 1 0 auto

.site-header-right
    align-items: center
    justify-content: flex-end

.site-header-mobile
    align-items: center
    display: none

    +at-max-width($bp-mobile)
        display: flex

.header-logo
    width: 110px

    svg
        margin-top: 2px

    &:hover
        svg
            fill: $color-brand-orange

.user
    position: relative

.user-info
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer

.user-avatar
    width: 30px
    height: 30px
    border-radius: 50%

.user-avatar,
.user-name
    margin-right: 10px

.toggle-user-menu
    +css-arrow(bottom, 5px, 1px, #000)
    transform-origin: 75% 75%

.user-menu
    position: absolute
    top: 50px
    right: 0
    background-color: #fff
    padding: 26px
    display: none
    box-shadow: $box-shadow-light
    z-index: 10

.user-has-visible-user-menu
    .user-menu
        display: block
        animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both

    .toggle-user-menu
        transform: rotateZ(-135deg)

.user-menu-link
    text-transform: uppercase
    text-decoration: none
    color: #565656
    padding: 10px 0
    display: flex
    align-items: center
    cursor: pointer

    .icon-wrap
        width: 20px
        height: 20px
        display: inline-block
        margin-right: 10px

    .icon
        max-width: 100%
        max-height: 100%

    .icon-sign-out
        width: 18px
        height: 18px

    &:hover
        color: #000

        .icon
            fill: #000

.toggle-mobile-menu-icon
    width: 40px
    height: 40px
    cursor: pointer
    position: relative
    display: block
    padding-top: 19px
    padding-left: 8px
    margin-left: -8px

    span,
    span:after,
    span:before
        cursor: pointer
        border-radius: 1px
        height: 2px
        width: 25px
        background-color: #000
        position: absolute
        display: block
        content: ' '
        transition: transform 0.2s ease-in-out
        transform-origin: 5% 50%

    span:before
        top: -8px

    span:after
        bottom: -8px

.toggle-mobile-menu-label
    display: none

.has-visible-mobile-menu
    .toggle-mobile-menu-icon
        span
            background-color: transparent

            &:before
                transform: rotate(45deg)

            &:after
                transform: rotate(-45deg)

.toggle-shopping-cart
    position: relative
    cursor: pointer
    margin-left: 20px

    svg
        width: 25px
        height: 25px
        display: block

.cart-count-bubble
    aspect-ratio: 1 / 1 // sass-lint:disable-line no-misspelled-properties
    position: absolute
    min-width: 2em
    bottom: 10px
    left: 15px
    display: flex
    align-items: center
    color: #fff
    background-color: $color-brand-orange
    border-radius: 50%
    padding: 4px
    font-size: 11px
    text-align: center

.cart-count
    position: relative
    display: block
    width: 100%

    &:after
        content: attr(data-cart-item-count)

.toggle-shopping-cart-has-empty-cart
    .cart-count-bubble
        display: none

