.orders
    @extend %box-default

%table
    display: block
    max-width: 100%

    thead,
    tbody,
    tfoot
        display: block

    tr
        display: flex
        flex-wrap: wrap
        width: 100%
        border-bottom: 1px solid $color-grey-light
        transition: all 0.5s ease

        &:last-child
            border-style: none

    th, td
        text-align: left
        padding: 1.2em 1em
        display: block

    th
        text-transform: uppercase
        hyphens: auto


    p
        //@extend %ellipsis
        margin: 0
        max-width: none

    .text-align-right
        text-align: right

    .tfoot-show-more
        width: 100%
        text-align: center
        text-transform: uppercase
        border-top: 1px solid $color-grey-light
        letter-spacing: 0.05em
        padding-top: 30px
        padding-bottom: 30px
        transition: color 0.5s ease

        svg
            transition: fill 0.5s ease

        .icon-show-more
            display: inline-block
            margin-top: 10px
            width: 30px

        &:hover
            color: $color-brand-orange
            cursor: pointer

            svg
                fill: $color-brand-orange

.table-row-has-link
    &:hover
        background-color: $color-grey-lighter
        color: $color-brand-orange
        cursor: pointer

// Table inside wrapper
.table-boxed
    @extend %table

    th, td
        padding-left: 0

.table-default
    @extend %table
    margin-top: 30px
    background-color: #fff
    box-shadow: $box-shadow-light

    thead
        background-color: $color-grey-dark
        color: #fff

    button
        margin: 0
        display: inline-block

    .td-delete-row
        text-align: right

    .td-has-link
        text-decoration: underline
        cursor: pointer

        &:hover
            color: $color-brand-orange


.table-cols-3
    th, td
        width: calc(#{columns-to-percent(3)})

.table-cols-4
    th, td
        width: calc(#{columns-to-percent(4)})

.table-cols-5
    th, td
        width: calc(#{columns-to-percent(5)})

.table-cols-6
    th, td
        width: calc(#{columns-to-percent(6)})

.table-cols-7
    th, td
        width: calc(#{columns-to-percent(7)})

        +at-max-width(1200px)
            width: calc(#{columns-to-percent(6)})

            &:last-child
                display: none

.table-cols-8
    th, td
        width: calc(#{columns-to-percent(8)})

.filter-table
    display: flex
    justify-content: flex-end

    .field-select-input
        width: auto

.status
    td:last-child
        p
            padding: 8px
            color: #fff
            text-align: center
            border-radius: 3px

.table-has-status-notice
    td, th
        &:first-child
            padding-left: 40px

.status-notice
    td:first-child
        position: relative

        &:after
            content: '!'
            width: 16px
            height: 16px
            border-radius: 50%
            color: #fff
            background-color: $color-brand-orange
            display: flex
            justify-content: center
            align-items: center
            margin-right: 5px
            position: absolute
            top: 23px
            left: 16px
            font-size: 11px

.td-has-link
    p
        text-decoration: underline

    &:hover
        cursor: pointer

        p
            color: $color-brand-orange

.status-paid td:last-child p
    background-color: $color-status-paid

.status-unpaid td:last-child p
    background-color: $color-status-unpaid

.status-overdue td:last-child p
    background-color: $color-status-overdue

.status-closed td:last-child p
    background-color: $color-status-done

.status-pending td:last-child p
    background-color: $color-status-in-progress

.status-open td:last-child p
    background-color: $color-status-open

.status-rejected td:last-child p
    background-color: $color-status-rejected

.grid-edit-user
    display: grid
    grid-gap: 20px
    align-items: center
    grid-template-columns: fit-content(300px) auto fit-content(300px)

.table-sortable
    .th-sortable
        position: relative
        padding-right: 44px

.column-is-sortable
    position: absolute
    right: 24px
    top: 23px
    width: 14px
    height: 16px
    display: flex
    flex-direction: column
    justify-content: space-between

    &:before,
    &:after
        content: ' '
        width: 0
        height: 0
        opacity: 0.4

    &:before
        border-left: 6px solid transparent
        border-right: 6px solid transparent
        border-bottom: 6px solid #fff

    &:after
        border-left: 6px solid transparent
        border-right: 6px solid transparent
        border-top: 6px solid #fff

.column-is-sorted-asc
    &:before
        opacity: 1

.column-is-sorted-desc
    &:after
        opacity: 1

.table-header
    margin-top: 30px

    + .table-default
        margin-top: 10px

.table-footer
    display: flex
    justify-content: space-between
    margin-top: 10px

.table-pagination
    display: flex
    align-items: center

    span,
    b
        margin: 0 2px

    button
        margin: 0
        width: 40px
        height: 40px
        border-radius: 50%
        background-size: 24px 24px
        background-repeat: no-repeat
        background-position: center center

        &:active
            transform: scale(0.85)

    .first
        background-image: url('../../images/icons/arrow-first.svg')
        background-position: left center

    .previous
        background-color: #fff
        background-image: url('../../images/icons/arrow-previous.svg')

    .next
        background-color: #fff
        background-image: url('../../images/icons/arrow-next.svg')

    .last
        background-image: url('../../images/icons/arrow-last.svg')
        background-position: right center

    .page-count
        margin: 0 8px

.table-size
    position: relative
    display: flex
    justify-content: center

    &:after
        content: ''
        position: absolute
        top: 50%
        right: 0
        width: 20px
        height: 20px
        margin-top: -8px
        pointer-events: none
        +css-arrow(bottom, 12px, 2px, #000)

.table-size-select
    padding-right: 24px

.table-fixed
    border-collapse: collapse
    width: 100%
    margin-top: 30px
    background-color: #fff
    box-shadow: $box-shadow-light

    thead
        background-color: $color-grey-dark
        color: #fff

    th
        text-transform: uppercase
        font-family: 'droid_sansbold', sans-serif
        hyphens: auto

    tr
        border-bottom: 1px solid $color-grey-light

        &:last-child
            border-style: none

    th, td
        text-align: left
        padding: 1.2em 1em
        vertical-align: top

.single-order,
.single-invoice
    p + p
        margin-top: -1em

    table
        thead, tbody
            th, td
                text-align: right

                p
                    margin: 0

                &:nth-child(1),
                &:nth-child(2)
                    text-align: left

        tfoot
            td
                &:last-child
                    text-align: right

    tr:last-child
        border-bottom: 1px solid $color-grey-light