.page-content-home
    .sales-premodul,
    .sales-contura,
    .footnote-contura
        display: none

    .sales-numbers
        width: 400px

        +at-max-width($bp-sales-figures)
            width: 100%

    .sales-items
        flex-direction: column
        min-height: 100%

        margin-bottom: $margin-medium

        +at-max-width($bp-sales-figures)
            width: 100%
            margin-left: -#{$margin-medium}
            flex-direction: row

    .sales-item
        flex: 1 1 auto

        +at-max-width($bp-sales-figures)
            width: calc(50% - calc($margin-medium / 2))
            margin-left: $margin-medium
            flex: 0 0 auto

.delivery-lists
    @extend %box-default
    width: 100%
    margin-top: 30px
