.shop-items
    display: grid
    grid-gap: 20px
    grid-template-columns: repeat(5, minmax(0, 20%))

    +at-max-width(2200px)
        grid-template-columns: repeat(4, minmax(0, 25%))

    +at-max-width(1600px)
        grid-template-columns: repeat(3, minmax(0, 33.333333%))

    +at-max-width(800px)
        grid-template-columns: minmax(0, 50%) minmax(0, 50%)

.shop-item
    @extend %block-light
    padding: 20px
    text-align: center
    text-decoration: none
    color: $color-font-body
    display: flex
    flex-direction: column
    transition: box-shadow 0.2s ease
    &:hover
        text-decoration: none
        box-shadow: 1px 0 20px rgba(0, 0, 0, 0.2)
    &.inactive
        opacity: 50%
        

.shop-item-description
    opacity: 0.6

.main-content-shop
    max-width: none

.shop-item-figure
    position: relative
    width: 100%
    min-height: 1px // Fixes flexbox in IE11

    &:before
        content: ' '
        display: block
        width: 100%
        padding-top: 100%

.shop-item-figure-content
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden

    img
        max-height: 100%
        flex: 0 0 auto // Fixes flexbox in IE11