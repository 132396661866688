.list-print
    img
        display: block

.list-print-items
    display: flex
    flex-wrap: wrap
    margin-left: -30px

.list-print-item
    width: calc(25% - 30px)
    margin-left: 30px
    margin-bottom: 30px
    background-color: #fff
    box-shadow: $box-shadow-light
    display: flex
    flex-direction: column

    +at-max-width(1600px)
        width: calc(50% - 30px)

.list-print-content
    padding: 20px
    background-color: $color-grey-dark
    flex: 1 0 auto

    h3, p
        color: #fff
        text-align: center