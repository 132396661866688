.order-checkout
    display: flex
    gap: 10px // sass-lint:disable-line no-misspelled-properties

    .shipping
        width: 100%
        flex: 7

    .cart
        width: 100%
        flex: 5

    .row
        width: 100%
        display: flex
        flex-direction: row
        padding: 10px 0

        *
            width: 100%

        label
            margin: 0 0 10px 1em

.checkout-form
    width: 100%

.order-checkout-footer
    display: flex
    margin-top: 2em

    .button-submit
        flex: 3
        margin: auto 0

    .button--go-back
        flex: 2
        color: $color-brand-orange
        margin: auto 0
        text-align: start
        padding: 0

.select-checkbox-container
    margin-top: 1em
