html
    box-sizing: border-box

*
    box-sizing: inherit

    &:before,
    &:after
        box-sizing: inherit

img, svg
    max-width: 100%
    height: auto

input,
textarea,
select
    outline: 0
    -webkit-appearance: none // sass-lint:disable-line no-vendor-prefixes
    box-shadow: none
    border-style: none
    background-color: transparent

button
    border-style: none
    background-color: transparent
    padding: 0
    outline: 0
    cursor: pointer
