// Color swatches
$color-grey-lighter: #f0f0f1
$color-grey-x-light: #f1f1f1
$color-grey-light: #bdc1c2
$color-grey-medium: #999
$color-grey-dark: #343536
$color-brand-orange: #f06d22
$color-red: #e82e11

// Colors
$color-font-body: $color-grey-dark
$color-link: $color-brand-orange
$color-menu-item: $color-grey-medium
$color-menu-item-current: #fff
$color-user-menu-link: $color-grey-medium
$color-user-menu-hover: $color-font-body

$color-chart-current-year: $color-grey-dark
$color-chart-previous-year: silver

$color-delete: $color-red

$color-status-paid: #70c66d
$color-status-unpaid: #567994
$color-status-overdue: rgba(232, 41, 48, 0.84)
$color-status-rejected: rgba(232, 41, 48, 0.84)
$color-status-done: #70c66d
$color-status-in-progress: #567994
$color-status-open: #7b7b7b

// Overlay backdrop
$overlay-backdrop-filter: saturate(170%) blur(4px)
$overlay-backdrop-color: rgba(0, 0, 0, 0.2)

// Box shadow
$box-shadow-default: 0 0 12px rgba(0, 0, 0, 0.12)
$box-shadow-light: 1px 0 5px rgba(0, 0, 0, 0.15)


$font-family-body: 'droid_sansregular', sans-serif

// Margins
$margin-small: 15px
$margin-medium: 30px
$margin-large: 70px

// Widths
$width-navigation: 325px

// Breakpoints
$bp-mobile: 1000px
$bp-sales-figures: 1500px