.content-primary-confirm,
.content-primary-feedback
    display: none

.print-confirm
    .content-primary-order
        display: none

    .content-primary-confirm
        display: block

.print-submitted
    .content-primary-order
        display: none

    .content-primary-feedback
        display: block

.form-print-list
    max-width: none
