$shopping-cart-width: 450px
$shopping-cart-margin: 30px
$cart-header-height: 60px
$cart-footer-height: 200px
$agree-to-terms-conditions-height: 60px
$cart-note-textfield-height: 55px
$cart-note-height: $cart-note-textfield-height + 10px
$cart-border-color: #f1f1f1

.cart-container
    position: fixed
    width: $shopping-cart-width
    max-width: calc(100% - 3rem)
    //height: 100vh
    top: 0
    right: 0
    bottom: 0
    background: #fff
    transform: translateX(100%)
    z-index: 99999
    transition: transform 250ms cubic-bezier(0.2, 0.7, 0.4, 1)
    box-shadow: none

.cart-header
    height: $cart-header-height
    padding-left: $shopping-cart-margin
    padding-right: $shopping-cart-margin
    display: flex
    justify-content: center
    align-items: center
    position: relative
    //box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12)

    +at-max-width(750px)
        justify-content: flex-end

.cart-overlay
    content: ' '
    top: 0
    left: 0
    bottom: 0
    position: fixed
    background-color: rgba(255, 255, 255, 0.3)
    opacity: 0
    z-index: 10
    transition: opacity 0.7s

.has-visible-shopping-cart
    overflow: hidden
    height: 100%

    body
        overflow: hidden
        height: 100%

    .page
        filter: blur(8px)
        overflow: hidden
        height: 100%

    .overlay
        opacity: 1
        right: 0

    .cart-container
        transform: translateX(0)
        box-shadow: -5px 0 25px -7px rgba(0, 0, 0, 0.6)

.cart-content
    height: 100%
    display: flex
    flex-direction: column

.cart-title
    font-size: 1.1em
    margin: 0 20px -2px
    padding: 5px 0 10px
    cursor: pointer
    border-bottom: 2px solid transparent
    text-transform: uppercase

    +at-max-width(550px)
        font-size: 1em
        margin: 0 10px -2px

    +at-max-width(750px)
        margin: 0 0 -2px 30px

    &:after
        content: ' (' attr(data-cart-item-count) ')'

    &[data-cart-item-count=''],
    &[data-cart-item-count='0']
        &:after
            display: none

.cart-container-has-favorite-cart .cart-title-favorite-cart,
.cart-container-has-shopping-cart .cart-title-shopping-cart
    border-bottom-color: #555
    opacity: 1
    cursor: default

.cart-close
    width: 40px
    height: 40px
    cursor: pointer
    position: absolute
    top: 50%
    left: 10px
    margin-top: -20px
    //margin-right: -8px
    //box-shadow: 0 0 0 1px silver

    &:after,
    &:before
        content: ' '
        cursor: pointer
        border-radius: 1px
        height: 2px
        width: 25px
        background: #000
        position: absolute
        display: block
        left: 7px
        transition: all 0.2s ease-in-out
        transform-origin: 50% 50%
        top: 20px

    &:before
        transform: rotate(45deg)

    &:after
        transform: rotate(-45deg)

    &:hover
        &:after,
        &:before
            background-color: $color-brand-orange

.cart-main
    height: calc(100% - #{($cart-header-height + $cart-footer-height)})
    overflow-y: scroll
    -webkit-overflow-scrolling: touch // sass-lint:disable-line no-vendor-prefixes
    position: relative

.cart-footer
    height: $cart-footer-height
    padding-top: $shopping-cart-margin
    border-top: 1px solid $cart-border-color

    +at-max-width(750px)
        font-size: 18px

    .agree-to-terms-conditions
        display: flex
        align-items: flex-start
        //justify-content:
        margin: 20px 0 0

        input
            display: inline-block
            margin: 1px 7px 0 0

        label
            line-height: 1.2em
            font-size: 12px

.shopping-cart-checkout-btn
    display: block
    margin-top: 10px
    margin-left: auto
    margin-right: auto
    width: calc(100% - #{$shopping-cart-margin * 2})

.cart-container-has-shopping-cart
    .cart-content-has-agree-to-terms-conditions
        .cart-main
            height: calc(100% - #{$cart-header-height + $cart-footer-height + $agree-to-terms-conditions-height})

        .cart-footer
            height: #{$cart-footer-height + $agree-to-terms-conditions-height}

    .cart-content-has-cart-note
        .cart-main
            height: calc(100% - #{$cart-header-height + $cart-footer-height + $cart-note-textfield-height})

        .cart-footer
            height: #{$cart-footer-height + $cart-note-height}

        &.cart-content-has-agree-to-terms-conditions
            .cart-main
                height: calc(100% - #{$cart-header-height + $cart-footer-height + $cart-note-height + $agree-to-terms-conditions-height})

            .cart-footer
                height: #{$cart-footer-height + $cart-note-height + $agree-to-terms-conditions-height}

    .shopping-cart-line-items
        display: block

    .favorite-cart-line-items
        display: none

    .cart-main
        height: calc(100% - #{$cart-header-height + $cart-footer-height})

.cart-container-has-favorite-cart
    .favorite-cart-line-items
        display: block

    .shopping-cart-line-items
        display: none

    .cart-footer
        display: none

.cart-line-items
    padding-top: 10px
    margin-bottom: 40px
    display: flex
    flex-direction: column
    height: 100%

.empty-cart
    display: flex
    justify-content: center
    align-items: center
    height: 100%

.shopping-cart-line-item
    border-top: 1px solid $cart-border-color
    margin-bottom: 20px
    padding-top: 20px
    padding-left: $shopping-cart-margin
    padding-right: $shopping-cart-margin

    &:first-child
        border-top-style: none

.shopping-cart-line-item-main
    display: flex

.shopping-cart-line-item-content
    display: flex
    width: 100%
    color: $color-font-body
    text-decoration: none
    margin-right: 30px

.cart-line-item-remove
    width: 40px
    height: 40px
    cursor: pointer
    position: relative
    transition: color 0.3s ease
    margin-top: -12px
    margin-right: -10px
    display: flex
    justify-content: center
    align-items: center

    svg
        max-width: 15px
        max-height: 15px

    &:hover
        color: $color-brand-orange

.shopping-cart-line-item-figure
    width: 90px
    height: 90px
    margin-right: 10px
    display: flex
    justify-content: center
    align-items: center

    img
        max-width: 90px
        max-height: 90px

.shopping-cart-line-item-details
    flex: 1 0 0
    display: flex
    justify-content: space-between

.shopping-cart-line-item-footer
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 5px

.shopping-cart-line-item-price
    +at-max-width(750px)
        font-size: 18px

.shopping-cart-line-item-quantity
    display: flex
    align-items: center
    border: 1px solid #000
    border-radius: 20px

    .shopping-cart-quantity-value
        -moz-appearance: textfield // sass-lint:disable-line no-vendor-prefixes

.shopping-cart-line-item-quantity-disabled
    display: inline-block
    width: 90px
    text-align: center

.shopping-cart-quantity-btn
    //box-shadow: 0 0 0 1px #e3e3e3 inset
    color: #000
    background-color: transparent
    width: 23px
    height: 23px
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    transition: box-shadow 0.3s ease, scale 0.5s ease
    line-height: 0
    outline: 0
    padding: 0
    margin: 0
    border-style: none
    cursor: pointer

    +at-max-width(750px)
        width: 40px
        height: 40px

    i
        width: 20px
        height: 20px
        cursor: pointer
        position: relative

        &:after,
        &:before
            content: ' '
            cursor: pointer
            border-radius: 1px
            height: 1px
            width: 12px
            background-color: #000
            position: absolute
            display: block
            top: 10px
            right: 4px
            transition: transform 0.2s ease-in-out, background-color 0.3s ease
            transform-origin: 50% 50%

            +at-max-width(750px)
                width: 15px
                top: 10px
                right: 2px

        &:before
            transform: rotate(90deg)

        &:after
            transform: rotate(-180deg)

    &:disabled
        pointer-events: none

.shopping-cart-quantity-decrease
    i:before
        display: none

@keyframes loading-spinner
    0%
        transform: rotate(0deg)

    100%
        transform: rotate(360deg)


.shopping-cart-quantity-value
    text-align: center
    width: 35px
    padding: 6px 4px
    margin: 0 3px
    border-style: none
    appearance: none
    border-radius: 0

    &::-webkit-inner-spin-button, // sass-lint:disable-line no-vendor-prefixes
    &::-webkit-outer-spin-button // sass-lint:disable-line no-vendor-prefixes
        -webkit-appearance: none // sass-lint:disable-line no-vendor-prefixes
        margin: 0

    &:disabled
        color: #9a9a9a
        border-color: transparent
        background-color: #e3e3e2

.shopping-cart-line-item-title
    font-size: 15px
    line-height: 1.1em
    font-weight: 400
    margin: 0

.cart-summary
    display: flex
    justify-content: space-between

.checkout-cart
    .cart-footer
        padding-top: 10px

    .cart-summary
        opacity: 0.6

    .cart-summary-grandtotal
        text-transform: uppercase
        opacity: 1

.cart-summary,
.shopping-cart-disclaimer
    padding-left: $shopping-cart-margin
    padding-right: $shopping-cart-margin

.cart-summary-grandtotal
    border-top: 1px solid $cart-border-color
    padding-top: 10px

.cart-summary-vat
    padding-bottom: 10px

.shopping-cart-value-updated
    animation: blink-1 0.6s both

.shopping-cart-disclaimer
    opacity: 0.6

.shopping-cart-checkout-form
    margin-top: 10px
    text-align: right

.cart-note-textfield
    width: 100%
    box-shadow: none
    border: 1px solid silver
    min-height: $cart-note-textfield-height
    height: $cart-note-textfield-height
    padding: 8px
    margin-top: 10px
    font-size: 14px
    resize: none

.desktop
    .shopping-cart-line-item-remove
        &:hover
            opacity: 1

            &:after,
            &:before
                background-color: red

    .shopping-cart-quantity-btn
        &:hover
            box-shadow: 0 0 0 2px #000 inset

        &:hover:disabled,
        &:disabled
            box-shadow: 0 0 0 0 #000 inset

.cart-container-has-empty-cart
    .cart-footer
        display: none