.checkout-container
    display: grid
    grid-template-columns: minmax(0, 50%) minmax(0, 50%)
    min-height: 100vh

    h1
        text-align: center

.checkout-content
    padding: 40px

.checkout-content-secondary
    background-color: #fff
    padding-top: 10px

    .cart-container
        transform: translateX(0)
        width: 100%
        max-width: none
        position: static