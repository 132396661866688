.cpq-summary
    .cpq-page
        display: block
        width: 100%

    .config-items

        .sub-title
            padding-bottom: 10px
            width: 100%
            text-align: center

        .item-list
            width: 100%

        .campaign-text

            font-style: italic

.cpq-config-table
    tr
        flex-wrap: unset

        th
            text-align: left
        td
            padding: 0 5px 5px 0
            text-align: left

        td:first-of-type, td:nth-of-type(3)
            font-weight: 600

        td:nth-of-type(2)
            width: 30%

        .campaign-text
            font-size: 0.9rem

    .item

        &--header
            font-weight: 600

.config-description
    width: 100%
    padding: 5px 10px
    background-color: #fff

    .config-info-string
        width: 100%

        p
            margin: 0
            padding: 0

.print-icon
    margin-top: 30px
    width: 30px
    height: auto

    &:hover
        cursor: pointer

.config-summary-footer
    @extend %box-default
    width: 100%
    min-height: 20px
    background-color: #fff
    padding: 10px
    font-weight: 600
    display: flex

    .button
        margin: 0 10px
        color: #fff

        &--cancel
            background: $color-status-overdue

        &--add
            background-color: $color-status-paid
